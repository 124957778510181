<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.09502C13.543 3.03241 13.0755 3 12.6 3C7.29807 3 3 7.02944 3 12C3 16.9706 7.29807 21 12.6 21C13.0755 21 13.543 20.9676 14 20.905"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M21 12L11 12M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24',
    },
    width: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: 'primary-gray-700',
    },
  },
  computed: {
    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
